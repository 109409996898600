import {
  createRouter,
  createWebHistory
} from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [{
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [{
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },
      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/mall/Order.vue")
      },
      {
        path: "/question",
        name: "question",
        component: () => import("../views/mall/Question.vue")
      },
      {
        path: "/team_Rank",
        name: "team_Rank",
        component: () => import("../views/team/Team_Rank.vue")
      },
      {
        path: "/game",
        name: "game",
        component: () => import("../views/team/Game.vue")
      },
      {
        path: "/playUser",
        name: "playUser",
        component: () => import("../views/user/PlayUser.vue")
      },
      {
        path: "/dycUserConcern",
        name: "dycUserConcern",
        component: () => import("../views/DycUser/DycUserConcern.vue")
      },
      {
        path: "/dycComment",
        name: "dycComment",
        component: () => import("../views/DycUser/DycComment.vue")
      },
      {
        path: "/dycGift",
        name: "dycGift",
        component: () => import("../views/DycUser/DycGift.vue")
      },
      {
        path: "/dycReward",
        name: "dycReward",
        component: () => import("../views/DycUser/DycReward.vue")
      },
      {
        path: "/dycTopic",
        name: "dycTopic",
        component: () => import("../views/DycUser/DycTopic.vue")
      },
      {
        path: "/dycTopicConcern",
        name: "dycTopicConcern",
        component: () => import("../views/DycUser/DycTopicConcern.vue")
      },
      {
        path: "/dynamic",
        name: "dynamic",
        component: () => import("../views/DycUser/Dynamic.vue")
      },
      {
        path: "/dynamicTopic",
        name: "dynamicTopic",
        component: () => import("../views/DycUser/DynamicTopic.vue")
      },
      {
        path: "/teamAttent",
        name: "teamAttent",
        component: () => import("../views/team/TeamAttent.vue")
      },
      {
        path: "/playEvaluate",
        name: "playEvaluate",
        component: () => import("../views/play/PlayEvaluate.vue")
      },
      {
        path: "/scoreRecord",
        name: "scoreRecord",
        component: () => import("../views/score/ScoreRecord.vue")
      },
      {
        path: "/activityRecord",
        name: "activityRecord",
        component: () => import("../views/score/ActivityRecord.vue")
      },
      {
        path: "/changeRecord",
        name: "changeRecord",
        component: () => import("../views/score/ChangeRecord.vue")
      },
      {
        path: "/rechargeRecord",
        name: "rechargeRecord",
        component: () => import("../views/score/RechargeRecord.vue")
      },
      {
        path: "/team",
        name: "team",
        component: () => import("../views/team/Team.vue")
      },
      {
        path: "/teamRecord",
        name: "teamRecord",
        component: () => import("../views/team/TeamRecord.vue")
      },
      {
        path: "/checkRecord",
        name: "checkRecord",
        component: () => import("../views/play/CheckRecord.vue")
      },
      {
        path: "/VoiceAuthentication",
        name: "VoiceAuthentication",
        component: () => import("../views/play/VoiceAuthentication.vue")
      },
      {
        path: "/authentication",
        name: "authentication",
        component: () => import("../views/play/Authentication.vue")
      },
      {
        path: "/teamDynamic",
        name: "teamDynamic",
        component: () => import("../views/team/TeamDynamic.vue")
      },
       {
        path: "/finDraw",
        name: "finDraw",
        component: () => import("../views/user/FinDraw.vue")
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router